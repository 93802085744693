import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Image } from 'react-datocms'
import { useIntl } from 'react-intl'

import Block from '../components/blocks'
import { NextConcertList } from '../components/ConcertList'
import { ButtonLink } from '../components/Buttons'
import { today } from '../utils'

const titleCss = css`
  font-size: var(--font-size-even-bigger);
  text-align: center;
  margin-bottom: var(--space-larger);
`

const concertsCss = css`
  margin-top: var(--space-large);
`

const BandItemPage = ({
  data: {
    dato: { page, pageConcert, allConcerts }
  }
}) => {
  const { formatMessage: t } = useIntl()
  const { name, cover, content } = page

  const newConcerts = useMemo(() => {
    const d = today()
    const formattedConcertDates = allConcerts
      .reverse()
      .map(({ date, ...rest }) => ({
        ...rest,
        date: new Date(date)
      }))
    return formattedConcertDates.filter((c) => c.date >= d).slice(0, 2)
  }, [allConcerts])

  return (
    <>
      <h1 className="c-large" css={titleCss}>
        {name}
      </h1>
      <div className="c-large separator" />
      <div className="c-large" css={concertsCss}>
        <NextConcertList
          concerts={newConcerts}
          emptyTitle={t({ id: 'band no future concerts' })}
        />
        <ButtonLink to={pageConcert.slug}>
          {t({ id: 'show all dates' })}
        </ButtonLink>
      </div>
      <Image
        className="block"
        data={cover.fluidFullWidth}
        style={{ display: 'block' }}
      />
      {content.map(({ _modelApiKey, __typename, id, ...rest }) => (
        <Block type={_modelApiKey} key={id} {...rest} />
      ))}
    </>
  )
}

export default BandItemPage

export const query = graphql`
  query ($id: DATO_ItemId!, $locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      pageConcert(locale: $locale) {
        id
        slug: localeSlug
      }
      page: band(filter: { id: { eq: $id } }, locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        name
        cover {
          ...ImageFragment
        }
        content {
          ... on DATO_TitleRecord {
            ...TitleRecordFragment
          }
          ... on DATO_ParagraphRecord {
            ...ParagraphRecordFragment
          }
          ... on DATO_QuoteRecord {
            ...QuoteRecordFragment
          }
          ... on DATO_ImageGridRecord {
            ...ImageGridRecordFragment
          }
          ... on DATO_GalleryRecord {
            ...GalleryRecordFragment
          }
          ... on DATO_ContentVideoRecord {
            ...VideoRecordFragment
          }
        }
      }
      allConcerts(filter: { band: { eq: $id } }, orderBy: date_DESC) {
        ...ConcertRecordFragment
      }
    }
  }
`
